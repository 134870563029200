
/*Section - 1*/
.ims-sec-1 {
    background-color: #AAD4D7;
    height: 120vh;
}

.ims-sec-1-p {
    font-weight: 600;
    color: black;
}

h2 {
    line-height: 64px;
    font-family: 'Montserrat';
    font-size: 48px;
    font-weight: 700;
    color: #18191F;
}

.date-night-logo {
    width: 82px;
    height: 82px;
}

.date-night-text-logo {
    width: 82px;
}

h3 {
    font-size: 18px;
}


@media(max-width:600px) {
    .app-screen-shots {
        width: 360px;
    }

    .ims-sec-1 {
        height: 200vh;
    }
}

@media(max-width:380px) and (max-height:700px) {
    .app-screen-shots {
        width: 360px;
    }
}


/*Section - 1*/

