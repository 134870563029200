/*Footer*/

.footer {
    background-color: black;
}

.ims-btm-logo {
    width: 251px;
    height: 73px;
    filter: grayscale(1);
}

.ims-btm-logo:hover {
    filter: grayscale(0);
    transition: ease-in-out 500ms;
}

.ims-ftr-colmn-one {
    color: white;
    font-family: 'Montserrat';
    font-size: 20px;
}

.ims-ftr-colmn-two {
    margin-top: 1rem;
}

.ftr-colmn-two-link {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    color: #FFFFFF!important;
    letter-spacing: 1px;
}

h6 {
    color: #F95B42;
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.input-email {
    color: #F95B42;
    padding-top: 19px;
    padding-bottom: 19px;
}

.input-email::placeholder {
    color: #F95B42;
    font-family: 'Montserrat';
    font-weight: 500;
}

.email {
    color: #F95B42;
    font-weight: 500;
    font-family: 'Montserrat';
}

.reg-no {
    font-weight: 700;
    font-family: 'Montserrat';
    letter-spacing: 2px;
}

@media(min-width:600px) {
    .btm-cop-right {
        color: #D9DBE1;
        font-size: 12px;
        font-weight: 400;
    }
    .ims-send-email {
        position: absolute;
        top: 80px;
        left: 318px!important;
        width: 50px;
        height: 32px;
        background: none;
    }
}

@media(max-width:700px) {
    .btm-cop-right {
        color: #D9DBE1;
        font-size: 12px;
        font-weight: 400;
    }
    .ims-send-email {
        position: absolute;
        top: 80px;
        left: 318px;
        width: 30px;
        height: 32px;
        background: none;
    }
    .ims-btm-logo {
        width: 251px;
        height: 73px;
        margin-left: -16px;
    }
}
/*Footer*/

