/*NavBar*/

.nav-link {
    color: white;
}

.nav-link:hover {
    padding-bottom: 1px;
    border-bottom: 0.9px solid #FFFFFF;
    color: #F95B42;
}

.navbar.scrolled-active {
    background-color: #2E2E2E;
    transition:  200ms linear;
}

/*NavBar*/

.navbar-toggler-icon {
    color: #283990;
    font-size: 28px;
    outline: 0;
    border: none;
}



@media(min-width:900px) {
    .navbar-fixed-side {
        display: none;
    }
  
}

@media (max-width:600px) {
    .navbar-fixed-side {
        background: none;
        border: none;
        outline: none;
    }

    button:focus {
        outline: 0px dotted;
        outline: 0px auto -webkit-focusring-color;
    }
   
}